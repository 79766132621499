import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const selectCurrentEvent = state => state.dataBackupStream;

const selectEventByType = type => createSelector([selectCurrentEvent], event => (event?.type === type ? event : {}));

const useDataBackupEventStream = () => {
    const { payload: backupStatus } = useSelector(selectEventByType("data-backup-status"));

    return { backupStatus };
};

export default useDataBackupEventStream;
