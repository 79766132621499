import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type: null,
    payload: null
};

export const dataBackupStreamSlice = createSlice({
    name: "dataBackupStream",
    initialState,
    reducers: {
        setDataBackupMessage: (state, action) => {
            state.type = action.payload.type;
            state.payload = action.payload.payload;
        }
    }
});

export const { setDataBackupMessage } = dataBackupStreamSlice.actions;
export default dataBackupStreamSlice.reducer;
