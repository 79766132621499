import Endpoint from "./Endpoint";

export default class FileStorageEndpoint extends Endpoint {
    download(projectSlug) {
        return this.http.get(`${this.address}/${projectSlug}/userdata/download`);
    }

    upload(projectSlug) {
        return this.http.get(`${this.address}/${projectSlug}/userdata/upload`);
    }

    getBackups(serviceSlug) {
        return this.http.get(`${this.address}/userdata-backup/${serviceSlug}`);
    }

    prepareBackup(serviceSlug, createdAt) {
        return this.http.post(`${this.address}/userdata-backup`, { serviceSlug, createdAt });
    }
}
